/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Col,
  Flex,
  Row,
  Space,  
  Select,
  Pagination,
  Spin,
  Tag,
  Input,
} from "antd";
import CandidateLocationsModal from "../components/CandidateLocationsModal";
import "../styles/CandidatePage.css";
import { get, post } from "../lib/Api";
import Toast from "../components/Toast/Toast";
import { ToastContainer } from "react-toastify";
import CandidateAppliedJobCard from "../components/CandidateCard/CandidateAppliedJobCard";

const { Option, OptGroup } = Select;

const CandidateAppliedJobs = () => {
  const inputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [searchData, setSearchData] = useState({
    keywords: [],
    location: [],
    minExp: "",
    maxExp: "",
  });

  const [keywordInputValue, setKeywordInputValue] = useState("");
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const [selectedCityItems, setSelectedCityItems] = useState(
    searchData?.location || []
  );
  const [selectedStates, setSelectedStates] = useState([]);
  const [statesWithCities, setStatesWithCities] = useState([]);

  const fetchData = async (page) => {
    setLoading(true);
    const data = { ...searchData, page_no: page, page_size: pageSize };
    try {
      const response = await post("candidate/applied-jobs-list", data, "", true);
      if (response?.status === true) {
        setJobs(response.applied_jobs_list.results);
        setTotalJobs(response.count);
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStatesWithCities = async () => {
    setLoading(true);
    const res = await get(`master/state-wise-city-list`, {}, {}, true);
    if (res?.status === true) {
      setStatesWithCities(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
      fetchData(currentPage);
  }, [currentPage, pageSize, searchData]);

  useEffect(() => {
    fetchStatesWithCities();
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(current);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === "minExp" || id === "maxExp") {
      if (/^\d+(\.\d{0,2})?$/.test(value) || value === "") {
        setSearchData({
          ...searchData,
          [id]: value,
        });
      }
    } else {
      setSearchData({
        ...searchData,
        [id]: value,
      });
    }
  };

  const handleKeyPress = (e) => {
    if (
      (e.key === "Enter" && keywordInputValue.trim() !== "") ||
      (e.key === "Tab" && keywordInputValue.trim() !== "")
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      keywordInputValue.trim() !== ""
    ) {
      const newKeyword = keywordInputValue.trim();
      setSearchData({
        ...searchData,
        keywords: [...searchData.keywords, newKeyword],
      });
      setKeywordInputValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [keywordInputValue]);

  const handleKeywordInputChange = (e) => {
    setKeywordInputValue(e.target.value);
  };

  const handleKeywordRemove = (index) => {
    setSearchData((prevData) => {
      return {
        ...prevData,
        keywords: prevData.keywords.filter((_, i) => i !== index),
      };
    });
  };

  const handleResetData = async () => {
    setSearchData({
      keywords: [],
      location: [],
      minExp: "",
      maxExp: "",
    });
  };

  useEffect(() => {
    const newCityToStateMap = {};
    const newStateToCityMap = {};
    statesWithCities.forEach((state) => {
      newStateToCityMap[state.id] = state.cities.map((city) => city.id);
      state.cities.forEach((city) => {
        newCityToStateMap[city.id] = state.id;
      });
    });
  }, [statesWithCities]);

  const handleCityChange = (value) => {
    setSelectedCityItems(value);

    const newlySelectedStates = statesWithCities
      .filter((state) => state.cities.every((city) => value.includes(city.id)))
      .map((state) => state.id);

    setSelectedStates(newlySelectedStates);
    setSearchData({
      ...searchData,
      location: value,
    });
  };

  const handleStateChange = (stateId, stateCities) => {
    const cityIds = stateCities.map((city) => city.id);

    if (selectedStates.includes(stateId)) {
      setSelectedCityItems((prev) =>
        prev.filter((cityId) => !cityIds.includes(cityId))
      );
      setSelectedStates((prev) => prev.filter((id) => id !== stateId));

      setSearchData((prev) => ({
        ...prev,
        location: prev.location.filter(
          (cityId) => !cityIds.includes(cityId)
        ),
      }));
    } else {
      const newCityItems = [...new Set([...selectedCityItems, ...cityIds])];
      setSelectedCityItems(newCityItems);
      setSelectedStates((prev) => [...prev, stateId]);

      setSearchData((prev) => ({
        ...prev,
        location: newCityItems,
      }));
    }
  };

  const getOptions = () => {
    return statesWithCities
      .filter((state) => state.cities.length > 0)
      .map((state) => {
        const allCitiesSelected = state.cities.every((city) =>
          selectedCityItems.includes(city.id)
        );

        return (
          <OptGroup
            key={state.id}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={allCitiesSelected}
                  onChange={() => handleStateChange(state.id, state.cities)}
                  style={{ marginRight: 8 }}
                />
                {state.name}
              </div>
            }
          >
            {state.cities.map((city) => (
              <Option key={city.id} value={city.id}>
                {city.name}
              </Option>
            ))}
          </OptGroup>
        );
      });
  };

  const filterOption = (input, option) => {
    const state = statesWithCities.find((state) =>
      state.cities.some((city) => city.id === option.value)
    );

    const cityNameMatches = option?.children
      ?.toLowerCase()
      .includes(input.toLowerCase());
    const stateNameMatches = state?.name
      ?.toLowerCase()
      .includes(input.toLowerCase());

    return cityNameMatches || stateNameMatches;
  };

  const handleCandidateSelectChange = (candidateId) => {
    setSelectedCandidates((prevSelected) =>
      prevSelected.includes(candidateId)
        ? prevSelected.filter((id) => id !== candidateId)
        : [...prevSelected, candidateId]
    );
  };

  const showFilterModal = () => setIsFilterModalOpen(!isFilterModalOpen);
  const handleOk = () => setIsModalOpen(false);

  const items = [
    {
      key: "1",
      label: "Must have Keywords",
      children: (
        <div className="checkbox-list chips">
          <Flex
            wrap
            gap="small"
            className="chips-button"
            ref={inputRef}
            // style={{ marginBottom: "10px" }}
          >
            <Input
              id="Keywords"
              placeholder="Type Keyword to search"
              value={keywordInputValue}
              onChange={handleKeywordInputChange}
              onKeyDown={handleKeyPress}
            />
            <Space direction="horizontal" className="tags-wrapper">
              {searchData?.keywords?.map((val, index) => (
                <Tag
                  closable
                  onClose={(e) => {
                    e.preventDefault();
                    handleKeywordRemove(index);
                  }}
                  color="success"
                  key={index}
                >
                  {val}
                </Tag>
              ))}
            </Space>
          </Flex>
        </div>
      ),
    },
    {
      key: "2",
      label: "Location",
      children: (
        <div className="checkbox-list">
          <Select
            mode="multiple"
            placeholder="Select cities"
            value={selectedCityItems}
            onChange={handleCityChange}
            style={{ width: "100%", height: "auto" }}
            showSearch
            filterOption={filterOption}
          >
            {getOptions()}
          </Select>
        </div>
      ),
    },
    {
      key: "5",
      label: "Experience",
      children: (
        <div className="checkbox-list experience">
          <Row gutter={[16, 16]}>
            <Col xs={12} xl={12}>
              <Input
                id="minExp"
                placeholder={"Min. in years"}
                maxLength={3}
                value={searchData.minExp}
                onChange={handleInputChange}
              />
            </Col>
            <Col xs={12} xl={12}>
              <Input
                id="maxExp"
                placeholder={"Max. in years"}
                maxLength={3}
                value={searchData.maxExp}
                onChange={handleInputChange}
              />
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const onChange = (key) => {
    // console.log(key);
  };

  return (
    <>
      <section className="candidate">
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} lg={9} xl={7} className="candidate-mobile">
            <div className="candidate-left">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={filterIcon} alt="" />
                  Filters
                </h3>
                <Button type="link" block onClick={handleResetData}>
                  Clear all
                </Button>
              </Flex>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </div>
          </Col> */}
          <Col xs={24} lg={15} xl={17}>
            <div className="candidate-right">
              {/* <div className="candidate-right-head"></div> */}
              {loading ? (
                <Spin size="large" />
              ) : (
               jobs.length > 0 ? jobs?.map((job) => (
                  <CandidateAppliedJobCard
                    key={job.id}
                    job={job}
                    // handleVisibleDetails={handleVisibleDetails}
                    isSelected={selectedCandidates.includes(job.id)}
                    onSelectChange={() =>
                      handleCandidateSelectChange(job.id)
                    }
                    fetchData={fetchData}
                  />
                )) : "No data"
              )}
              <div className="candidate-right-head">
                <Pagination
                  current={currentPage}
                  total={totalJobs}
                  pageSize={pageSize}
                  onChange={onPageChange}
                  onShowSizeChange={onPageSizeChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "50", "100", "500"]}
                />
              </div>
            </div>
          </Col>
        </Row>
        {/* <button className="filter-btn btn-visible" onClick={showFilterModal}>
          <img src={filterAquaIcon} alt="" />
        </button> */}
        <CandidateLocationsModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
        />
        {/* <div className="mobile-menu"> */}
          {/* {isFilterModalOpen && (
            <div
              className="candidate-details-overlay"
              onClick={() => setIsFilterModalOpen(false)}
            />
          )} */}
          {/* <div className={`mobile-menu-modal ${isFilterModalOpen && "active"}`}>
            <button className="close" onClick={showFilterModal}>
              <SVGClose />
            </button>
            <div className="candidate-left">
              <Flex
                className="candidate-filter"
                gap="middle"
                justify="space-between"
                align="center"
              >
                <h3>
                  <img src={filterIcon} alt="" />
                  Filters
                </h3>
                <Button type="link" block onClick={handleResetData}>
                  Clear all
                </Button>
              </Flex>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </div>
          </div> */}
        {/* </div> */}
      </section>
      <ToastContainer />
    </>
  );
};

export default CandidateAppliedJobs;
