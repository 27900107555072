/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  addIcon,
  backIcon,
  chakkraLogo,
  importIcon,
  trashIcon,
} from "../assets";
import "../styles/CreateCandidate.css";
import { Input, Row, Col, Button, DatePicker, Select, Upload } from "antd";
import { get, post, put } from "../lib/Api";
import Toast from "../components/Toast/Toast";
import { UploadOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImportFileModal from "../components/modal/ImportFileModal ";
import ImportZipModal from "../components/modal/ImportZipModal";
import InvalidPage from "../components/InvalidPage/InvalidPage";
import SuccessPage from "../components/SuccessPage/SuccessPage";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const CandidateForm = () => {
  const location = useLocation();
  const { id, token } = useParams();
  const navigate = useNavigate();
  const dateFormat = "DD/MM/YYYY";
  const [loading, setLoading] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isImportZipModalOpen, setIsImportZipModalOpen] = useState(false);
  const [qualificationDropData, setQualificationDropData] = useState([]);
  const [designationDropData, setDesignationDropData] = useState([]);
  const [sourceDroData, setSourceDropData] = useState([]);
  const [countriesDropData, setCountriesDropData] = useState([]);
  const [statesDropData, setStatesDropData] = useState([]);
  const [citiesDropData, setCitiesDropData] = useState([]);
  const [preferredCitiesDropData, setPreferredCitiesDropData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [skills, setSkills] = useState([]);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isSelfSuccess, setIsSelfSuccess] = useState(false);

  const userType = localStorage.getItem("__user_type__");

  const isCreateMode = location.pathname === "/create-candidate";
  const isUpdateMode = location.pathname.startsWith("/update-candidate");
  const isSelfUpdateMode = location.pathname.startsWith("/s-update");
  const isRegisterMode = location.pathname.startsWith("/candidate/register");

  const mode = isCreateMode
    ? "create"
    : isUpdateMode
    ? "update"
    : isSelfUpdateMode
    ? "selfUpdate"
    : isRegisterMode
    ? "register"
    : null;

  const genderData = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const [candidateFormData, setCandidateFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    aadharNumber: "",
    dateOfBirth: null,
    gender: "",
    language: "",
    skills: "",
    currentCompany: "",
    preferredLocation: [],
    annualSalary: "",
    qualification: "",
    designation: "",
    experience: "",
    source: "",
    pinCode: "",
    city: "",
    state: "",
    country: 1,
    expectedSalary: "",
    noticePeriod: "",
    cv: null,
  });

  const [experiences, setExperiences] = useState([
    {
      companyName: "",
      startDate: "",
      endDate: "",
      position: "",
    },
  ]);

  const handleExperienceChange = (index, field, value) => {
    const updatedExperiences = [...experiences];
    updatedExperiences[index][field] = value;
    setExperiences(updatedExperiences);
  };

  const addExperience = () => {
    setExperiences([
      ...experiences,
      {
        companyName: "",
        startDate: "",
        endDate: "",
        position: "",
      },
    ]);
  };

  const removeExperience = (index) => {
    const updatedExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(updatedExperiences);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (
      id === "phoneNumber" ||
      id === "aadharNumber" ||
      id === "annualSalary" ||
      id === "pinCode" ||
      id === "expectedSalary" ||
      id === "noticePeriod"
    ) {
      if (/^[0-9]*$/.test(value) || value === "") {
        setCandidateFormData({
          ...candidateFormData,
          [id]: value,
        });
      }
    } else if (id === "experience") {
      if (/^\d+(\.\d{0,2})?$/.test(value) || value === "") {
        setCandidateFormData({
          ...candidateFormData,
          [id]: value,
        });
      }
    } else {
      setCandidateFormData({
        ...candidateFormData,
        [id]: value,
      });
    }
  };

  const handleBlur = async () => {
    if (mode === "register" && candidateFormData.phoneNumber) {
      setLoading(true);
      try {
        const response = await post(
          "candidate/check-phone-number",
          { phone_number: candidateFormData.phoneNumber },
          "",
          false
        );
        if (response?.status === true) {
          setLoading(false);
        } else {
          Toast({ message: response?.message, type: "info" });
          setLoading(false);
        }
      } catch (error) {
        console.error("API error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDateChange = (date, id) => {
    setCandidateFormData({
      ...candidateFormData,
      [id]: date,
    });
  };

  const handleSelectChange = (value, option) => {
    setCandidateFormData((prevData) => ({ ...prevData, [option.id]: value }));
  };

  const handleFileChange = ({ file }) => {
    if (file.type === "application/pdf") {
      setCandidateFormData({
        ...candidateFormData,
        cv: file,
      });
    } else {
      Toast({ message: "Please upload a PDF file", type: "error" });
    }
  };

  const fetchQualificationDropData = async () => {
    setLoading(true);
    const res = await get(`master/qualification-list`, "", {}, false);
    if (res?.status === true) {
      setQualificationDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchDesignationDropData = async () => {
    setLoading(true);
    const res = await get(`master/designation-list`, "", {}, false);
    if (res?.status === true) {
      setDesignationDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchSourceDropData = async () => {
    setLoading(true);
    const res = await get(`master/source-list`, "", {}, false);
    if (res?.status === true) {
      setSourceDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchCountries = async () => {
    setLoading(true);
    const res = await get(`master/country-list`, "", {}, false);
    if (res?.status === true) {
      setCountriesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchStates = async () => {
    setLoading(true);
    const res = await get(
      `master/state-list`,
      { country_id: candidateFormData.country },
      {},
      false
    );
    if (res?.status === true) {
      setStatesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchCities = async () => {
    setLoading(true);
    const res = await get(
      `master/city-list`,
      { state_id: candidateFormData.state },
      {},
      false
    );
    if (res?.status === true) {
      setCitiesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchPreferredCities = async () => {
    setLoading(true);
    const res = await get(`master/city-list`, "", {}, false);
    if (res?.status === true) {
      setPreferredCitiesDropData(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchLanguages = async () => {
    setLoading(true);
    const res = await get(`master/language-list`, "", {}, false);
    if (res?.status === true) {
      setLanguages(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  const fetchSkills = async () => {
    setLoading(true);
    const res = await get(`master/skills-list`, "", {}, false);
    if (res?.status === true) {
      setSkills(res.data);
      setLoading(false);
    } else {
      Toast({ message: res?.message, type: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (candidateFormData.country) {
      fetchStates();
    }
  }, [candidateFormData.country]);

  useEffect(() => {
    if (candidateFormData.state) {
      fetchCities();
    }
  }, [candidateFormData.state]);

  useEffect(() => {
    fetchCountries();
    fetchQualificationDropData();
    fetchDesignationDropData();
    fetchPreferredCities();
    fetchSourceDropData();
    fetchLanguages();
    fetchSkills();
  }, []);

  const handleResetForm = async () => {
    setCandidateFormData({
      name: "",
      phoneNumber: "",
      email: "",
      aadharNumber: "",
      dateOfBirth: null,
      gender: "",
      language: "",
      skills: "",
      currentCompany: "",
      preferredLocation: "",
      annualSalary: "",
      qualification: "",
      designation: "",
      experience: "",
      source: "",
      pinCode: "",
      city: "",
      state: "",
      country: 1,
      expectedSalary: "",
      noticePeriod: "",
      cv: null,
    });
  };

  const handleSaveCandidate = async () => {
    setLoading(true);
    try {
      if (
        !candidateFormData?.name ||
        !candidateFormData?.phoneNumber ||
        !candidateFormData?.aadharNumber ||
        !candidateFormData?.dateOfBirth ||
        !candidateFormData?.gender ||
        candidateFormData?.annualSalary === "" ||
        !candidateFormData?.qualification ||
        candidateFormData?.experience === "" ||
        !candidateFormData?.country ||
        !candidateFormData?.state ||
        !candidateFormData?.city ||
        !candidateFormData?.pinCode
      ) {
        Toast({ message: "Please fill all required fields", type: "error" });
        setLoading(false);
        return;
      }
      let res;

      const formData = new FormData();
      Object.keys(candidateFormData).forEach((key) => {
        formData.append(key, candidateFormData[key]);
      });

      formData.set(
        "dateOfBirth",
        candidateFormData?.dateOfBirth
          ? candidateFormData.dateOfBirth.format(dateFormat)
          : ""
      );

      formData.append(`experienceDetails`, JSON.stringify(experiences));

      if (mode === "create") {
        res = await post(
          `candidate/create`,
          formData,
          { "Content-Type": "multipart/form-data" },
          true
        );
      } else if (mode === "update") {
        res = await put(
          `candidate/update/${id}`,
          formData,
          { "Content-Type": "multipart/form-data" },
          true
        );
      } else if (mode === "register") {
        res = await post(
          `candidate/registration`,
          formData,
          { "Content-Type": "multipart/form-data" },
          false
        );
      } else {
        res = await put(
          `candidate/self-update/${id}/${token}`,
          formData,
          { "Content-Type": "multipart/form-data" },
          false
        );
      }

      if (res?.status === true) {
        setCandidateFormData({
          name: "",
          phoneNumber: "",
          email: "",
          aadharNumber: "",
          dateOfBirth: null,
          gender: "",
          language: "",
          skills: "",
          currentCompany: "",
          preferredLocation: "",
          annualSalary: "",
          qualification: "",
          designation: "",
          experience: "",
          source: "",
          pinCode: "",
          city: "",
          state: "",
          country: 1,
          expectedSalary: "",
          noticePeriod: "",
          cv: null,
        });
        Toast({
          message: `Candidate ${
            mode === "create" || mode === "register" ? "created" : "updated"
          } successfully`,
          type: "success",
        });
        if (mode === "update" && userType !== "candidate") {
          navigate("/search-candidates");
        }
        if (mode === "update" && userType === "candidate") {
          navigate("/candidate/profile");
        }
        if (mode === "selfUpdate") {
          setIsSelfSuccess(true);
        }
        if (mode === "register") {
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      } else {
        Toast({ message: res?.message, type: "error" });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message:
          mode === "create" || mode === "register"
            ? "Failed to create candidate"
            : "Failed to update candidate",
        type: "error",
      });
    }
  };

  const handleFileImport = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await post(
        `candidate/bulk-import`,
        formData,
        { "Content-Type": "multipart/form-data" },
        true
      );
      if (res?.status === true) {
        Toast({
          message: res?.message || "Imported successfully",
          type: "success",
        });
        window.location.reload(false);
      } else {
        Toast({
          message: res?.message || "Upload failed",
          type: "error",
        });
      }
      setLoading(false);
      return res.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      Toast({
        message: error.response?.message || "Upload failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const handleZipImport = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await post(
        `candidate/cv-bulk-import`,
        formData,
        { "Content-Type": "multipart/form-data" },
        true
      );
      if (res?.status === true) {
        Toast({
          message: res?.message || "Imported successfully",
          type: "success",
        });
        window.location.reload(false);
      } else {
        Toast({
          message: res?.message || "Upload failed",
          type: "error",
        });
      }
      setLoading(false);
      return res.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      Toast({
        message: error.response?.message || "Upload failed",
        type: "error",
      });
      setLoading(false);
      throw error;
    }
  };

  const fetchCandidate = async () => {
    setLoading(true);
    try {
      const response = await get(`candidate/list/${id}`, "", "", true);
      if (response?.status === true) {
        const candidate = response.data;
        setCandidateFormData({
          name: candidate.name,
          phoneNumber: candidate.phone_number,
          email: candidate.email,
          aadharNumber: candidate.aadhaar_card_number,
          dateOfBirth: candidate.dob ? dayjs(candidate.dob, dateFormat) : null,
          gender: candidate.gender,
          language: candidate.language_id,
          skills: candidate.skills_id,
          currentCompany: candidate.current_company,
          preferredLocation: candidate.preffered_location_id,
          annualSalary: candidate.annual_salary,
          qualification: candidate.qualification_id,
          designation: candidate.designation_id,
          experience: candidate.experience,
          source: candidate.source_id,
          pinCode: candidate.pin_code,
          city: candidate.city_id,
          state: candidate.state_id,
          country: candidate.country_id,
          expectedSalary: candidate.expected_salary,
          noticePeriod: candidate.notice_period,
          cv: candidate.cv,
        });

        if (candidate?.experience_details?.length > 0) {
          setExperiences(candidate.experience_details);
        }

        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSelfCandidateData = async () => {
    setLoading(true);
    try {
      const data = {
        candidate_profile_id: id,
        token: token,
      };
      const response = await post("candidate/self-data", data, "", false);
      if (response?.status === true) {
        const candidate = response.data;
        setCandidateFormData({
          name: candidate.name,
          phoneNumber: candidate.phone_number,
          email: candidate.email,
          aadharNumber: candidate.aadhaar_card_number,
          dateOfBirth: candidate.dob ? dayjs(candidate.dob, dateFormat) : null,
          gender: candidate.gender,
          language: candidate.language_id,
          skills: candidate.skills_id,
          currentCompany: candidate.current_company,
          preferredLocation: candidate.preffered_location_id,
          annualSalary: candidate.annual_salary,
          qualification: candidate.qualification_id,
          designation: candidate.designation_id,
          experience: candidate.experience,
          source: candidate.source_id,
          pinCode: candidate.pin_code,
          city: candidate.city_id,
          state: candidate.state_id,
          country: candidate.country_id,
          expectedSalary: candidate.expected_salary,
          noticePeriod: candidate.notice_period,
          cv: candidate.cv,
        });
        setLoading(false);
        setIsValidUrl(true);
      } else {
        setIsValidUrl(false);
        Toast({ message: response?.message, type: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch candidates", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (mode === "update") {
      fetchCandidate();
    } else if (mode === "selfUpdate") {
      fetchSelfCandidateData();
    }
  }, []);

  return (
    <>
      {isValidUrl && !isSelfSuccess && (
        <div
          style={{
            backgroundColor: "#F2F2F2",
            padding: "30px 20px",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              paddingBottom: "20px",
            }}
          >
            <div className="logo-content">
              {(mode === "selfUpdate" || mode === "register") && (
                <div className="chakkra-logo">
                  {" "}
                  <img
                    src={chakkraLogo}
                    alt="Chakkra Logo"
                    style={{ height: "50px", width: "155px" }}
                  />
                </div>
              )}
              <div>
                {" "}
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: 600,
                    lineHeight: "30px",
                    textAlign: "left",
                  }}
                >
                  {mode === "create"
                    ? "Create Candidate"
                    : mode === "update"
                    ? " Update Candidate"
                    : mode === "selfUpdate"
                    ? " Self Update Candidate"
                    : mode === "register"
                    ? "Candiadte Registration"
                    : null}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "17px",
                    textAlign: "left",
                  }}
                >
                  Onboard new candidate by assigning them specific clients and
                  work.
                </div>
              </div>
            </div>
            {mode === "register" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                title="Back to Login"
                onClick={() => navigate("/login")}
              >
                <img src={backIcon} alt="backIcon" width={20} height={20} />
              </div>
            )}

            {mode === "create" ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <div className="button-group">
                  <div>
                    <button
                      className="secondary-button"
                      title="Import bulk Recruiters"
                      onClick={() => {
                        setIsImportModalOpen(true);
                      }}
                    >
                      <img src={importIcon} alt="importIcon" />
                      Import Data
                    </button>
                  </div>
                </div>
                <div className="button-group">
                  <div>
                    <button
                      className="secondary-button"
                      title="Import bulk Recruiters"
                      onClick={() => {
                        setIsImportZipModalOpen(true);
                      }}
                    >
                      <img src={importIcon} alt="importIcon" />
                      Import CV
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ backgroundColor: "#FFFFFF", padding: "20px" }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="name">
                    Name <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="name"
                    placeholder="Enter Name"
                    className="candidate-create-input"
                    value={candidateFormData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="phoneNumber">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="phoneNumber"
                    placeholder="Enter Phone Number"
                    maxLength="10"
                    className="candidate-create-input"
                    value={candidateFormData.phoneNumber}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="email">Email</label>
                  <Input
                    id="email"
                    placeholder="Enter Email"
                    className="candidate-create-input"
                    value={candidateFormData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="aadharNumber">
                    Adhaar card Number <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="aadharNumber"
                    placeholder="Enter Aadhar card Number"
                    maxLength="12"
                    className="candidate-create-input"
                    disabled={mode === "selfUpdate"}
                    value={candidateFormData.aadharNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="dateOfBirth">
                    Date of Birth <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    id="dateOfBirth"
                    disabledDate={(current) => {
                      return current && current > dayjs().subtract(14, "years");
                    }}
                    className="candidate-create-input"
                    format={dateFormat}
                    value={candidateFormData.dateOfBirth}
                    onChange={(date) => handleDateChange(date, "dateOfBirth")}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="gender">
                    Gender <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="gender"
                    placeholder="Select a Gender"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    options={genderData.map((zone) => ({
                      value: zone.value,
                      label: zone.label,
                      id: "gender",
                    }))}
                    value={candidateFormData.gender || undefined}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="language">Language</label>
                  <Select
                    id="language"
                    mode="multiple"
                    showSearch
                    className="candidate-select-input"
                    placeholder="select Languages"
                    onChange={(e) => {
                      handleSelectChange(e, { id: "language" });
                    }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={languages.map((language) => ({
                      value: language.id,
                      label: language.name,
                      id: "language",
                    }))}
                    value={candidateFormData.language || undefined}
                    maxTagCount={4}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="country">
                    Country <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="country"
                    placeholder="Select a Country"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    options={countriesDropData.map((country) => ({
                      value: country.id,
                      label: country.name,
                      id: "country",
                    }))}
                    value={candidateFormData.country || undefined}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="state">
                    State <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="state"
                    placeholder="Select a State"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={statesDropData.map((state) => ({
                      value: state.id,
                      label: state.name,
                      id: "state",
                    }))}
                    value={candidateFormData.state || undefined}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="city">
                    City <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="city"
                    placeholder="Select a City"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={citiesDropData.map((city) => ({
                      value: city.id,
                      label: city.name,
                      id: "city",
                    }))}
                    value={candidateFormData.city || undefined}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="pinCode">
                    Pin Code <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="pinCode"
                    placeholder="Enter Pin Code"
                    maxLength="6"
                    className="candidate-create-input"
                    value={candidateFormData.pinCode}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="preferredLocation">Preferred Location</label>
                  <Select
                    id="preferredLocation"
                    mode="multiple"
                    placeholder="Select a Preferred Location"
                    className="candidate-select-input"
                    onChange={(e) => {
                      handleSelectChange(e, { id: "preferredLocation" });
                    }}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={preferredCitiesDropData.map((preferredCity) => ({
                      value: preferredCity.id,
                      label: preferredCity.name,
                      id: "preferredLocation",
                    }))}
                    value={candidateFormData.preferredLocation || undefined}
                    style={{
                      height: "auto",
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              {userType !== "candidate" && (
                <Col xs={24} md={8}>
                  <div className="candidate-create-input-div">
                    <label htmlFor="source">Source</label>
                    <Select
                      id="source"
                      placeholder="Select a Source"
                      className="candidate-select-input"
                      onChange={handleSelectChange}
                      options={sourceDroData.map((source) => ({
                        value: source.id,
                        label: source.name,
                        id: "source",
                      }))}
                      value={candidateFormData.source || undefined}
                    />
                  </div>
                </Col>
              )}
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="skills">Skills</label>
                  <Select
                    id="skills"
                    mode="multiple"
                    showSearch
                    className="candidate-select-input"
                    placeholder="select Skills"
                    onChange={(e) => {
                      handleSelectChange(e, { id: "skills" });
                    }}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={skills.map((skill) => ({
                      value: skill.id,
                      label: skill.name,
                      id: "skills",
                    }))}
                    value={candidateFormData.skills || undefined}
                    style={{
                      height: "auto",
                    }}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="qualification">
                    Qualification <span className="text-danger">*</span>
                  </label>
                  <Select
                    id="qualification"
                    placeholder="Select a Qualification"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={qualificationDropData.map((qualification) => ({
                      value: qualification.id,
                      label: qualification.name,
                      id: "qualification",
                    }))}
                    value={candidateFormData.qualification || undefined}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="experience">
                    Experience (in years)<span className="text-danger">*</span>
                  </label>
                  <Input
                    id="experience"
                    placeholder="Enter Experience"
                    maxLength="3"
                    className="candidate-create-input"
                    value={candidateFormData.experience}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="currentCompany">Current Company</label>
                  <Input
                    id="currentCompany"
                    placeholder="Enter Current Company"
                    className="candidate-create-input"
                    value={candidateFormData.currentCompany}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="designation">Designation</label>
                  <Select
                    id="designation"
                    placeholder="Select a Designation"
                    className="candidate-select-input"
                    onChange={handleSelectChange}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={designationDropData.map((designation) => ({
                      value: designation.id,
                      label: designation.name,
                      id: "designation",
                    }))}
                    value={candidateFormData.designation || undefined}
                  />
                </div>
              </Col>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="annualSalary">
                    Annual Salary <span className="text-danger">*</span>
                  </label>
                  <Input
                    id="annualSalary"
                    placeholder="Enter Annual Salary"
                    maxLength="10"
                    className="candidate-create-input"
                    value={candidateFormData.annualSalary}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              {mode === "update" && userType === "candidate" && (
                <>
                  <Col xs={24} md={8}>
                    <div className="candidate-create-input-div">
                      <label htmlFor="expectedSalary">Expected Salary</label>
                      <Input
                        id="expectedSalary"
                        placeholder="Enter Expected Salary"
                        maxLength="10"
                        className="candidate-create-input"
                        value={candidateFormData.expectedSalary}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Col>
                  <Col xs={24} md={8}>
                    <div className="candidate-create-input-div">
                      <label htmlFor="noticePeriod">
                        Notice Period (in days)
                      </label>
                      <Input
                        id="noticePeriod"
                        placeholder="Enter Notice Period"
                        maxLength="10"
                        className="candidate-create-input"
                        value={candidateFormData.noticePeriod}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Col>{" "}
                </>
              )}
            </Row>

            <Row gutter={[16, 16]} style={{ paddingTop: "15px" }}>
              <Col xs={24} md={8}>
                <div className="candidate-create-input-div">
                  <label htmlFor="cv">CV</label>
                  <br />
                  <Upload
                    className="candidate-create-input-div"
                    accept=".pdf"
                    showUploadList={false}
                    customRequest={({ file, onSuccess }) => {
                      onSuccess("ok");
                      handleFileChange({ file });
                    }}
                  >
                    {/* <Button icon={<UploadOutlined />}>Upload CV (PDF only)</Button> */}
                    {/* <Button className="create-modal-cancel-button">
                  Choose File{" "}
                </Button> */}
                    <Button
                      icon={<UploadOutlined />}
                      className="candidate-create-input"
                    >
                      Upload CV
                    </Button>
                    {/* <br /> */}
                    {candidateFormData?.cv?.name}
                  </Upload>
                </div>
              </Col>
            </Row>

            {userType === "candidate" && mode === "update" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  marginTop: "20px",
                }}
              >
                Experience Details{" "}
                <Button type="button" onClick={addExperience}>
                  <img src={addIcon} alt="add" width={20} height={20} />
                </Button>
              </div>
            )}
            {userType === "candidate" &&
              mode === "update" &&
              experiences.map((experience, index) => (
                <Row
                  gutter={[16, 16]}
                  style={{ paddingTop: "15px" }}
                  key={index}
                >
                  <Col xs={24} md={8}>
                    <div className="candidate-create-input-div">
                      <label htmlFor="companyName">
                        Comapny Name <span className="text-danger">*</span>
                      </label>
                      <Input
                        id="companyName"
                        placeholder="Company Name"
                        className="candidate-create-input"
                        value={experience.companyName}
                        onChange={(e) =>
                          handleExperienceChange(
                            index,
                            "companyName",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </Col>

                  <Col xs={24} md={8}>
                    <div className="candidate-create-input-div">
                      <label htmlFor="startDate">
                        Start Date <span className="text-danger">*</span>
                      </label>
                      <DatePicker
                        id="startDate"
                        className="candidate-create-input"
                        format="MM/YYYY"
                        picker="month"
                        value={
                          experience.startDate
                            ? dayjs(experience.startDate)
                            : null
                        }
                        onChange={(date) =>
                          handleExperienceChange(
                            index,
                            "startDate",
                            date ? date.format("YYYY-MM") : ""
                          )
                        }
                      />
                    </div>
                  </Col>

                  <Col xs={24} md={8}>
                    <div className="candidate-create-input-div">
                      <label htmlFor="endDate">End Date</label>
                      <DatePicker
                        id="endDate"
                        className="candidate-create-input"
                        format="MM/YYYY"
                        picker="month"
                        value={
                          experience.endDate ? dayjs(experience.endDate) : null
                        }
                        onChange={(date) =>
                          handleExperienceChange(
                            index,
                            "endDate",
                            date ? date.format("YYYY-MM") : ""
                          )
                        }
                      />
                    </div>
                  </Col>

                  <Col xs={24} md={8}>
                    <div className="candidate-create-input-div">
                      <label htmlFor="position">Position</label>
                      <Input
                        id="position"
                        placeholder="Position Name"
                        className="candidate-create-input"
                        value={experience.position}
                        onChange={(e) =>
                          handleExperienceChange(
                            index,
                            "position",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </Col>

                  {experiences.length > 1 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => removeExperience(index)}
                    >
                      <img src={trashIcon} alt="icon" width={25} height={25} />
                    </div>
                  )}
                </Row>
              ))}

            <hr
              style={{
                backgroundColor: "#ced7de",
                height: "1px",
                border: "none",
              }}
            />

            <div style={{ display: "flex", justifyContent: "end", gap: "8px" }}>
              <Button
                className="create-modal-cancel-button"
                onClick={handleResetForm}
              >
                Clear{" "}
              </Button>
              <Button
                className="create-modal-confirm-button"
                onClick={handleSaveCandidate}
                disabled={loading}
              >
                {/* {loading ? "Creating..." : "Create"} */}
                {loading
                  ? mode === "create" || mode === "register"
                    ? "Submitting..."
                    : "Updating..."
                  : mode === "create" || mode === "register"
                  ? "Submit"
                  : "Update"}
              </Button>
            </div>
          </div>

          <ImportFileModal
            isVisible={isImportModalOpen}
            onClose={() => setIsImportModalOpen(false)}
            onImport={handleFileImport}
            downloadSampleSheetUrl="candidate/sample-sheet"
            title="Import Excel File"
          />

          <ImportZipModal
            isVisible={isImportZipModalOpen}
            onClose={() => setIsImportZipModalOpen(false)}
            onImport={handleZipImport}
            // downloadSampleSheetUrl="candidate/sample-sheet"
            title="Import Zip File"
          />

          <ToastContainer />
        </div>
      )}
      {isSelfSuccess && <SuccessPage />}
      {!isValidUrl && <InvalidPage />}
    </>
  );
};

export default CandidateForm;
