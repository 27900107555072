import axios from "axios";
import qs from "qs";
import { Navigate } from "react-router-dom";
import Toast from "../components/Toast/Toast";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

const handleResponse = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error(response?.statusText);
};

const handleError = (error, navigate) => {
  if (error?.response?.status === 401) {
    localStorage.clear();
    Toast({
      message: "Need to login first. Please login and try again.",
      type: "error",
    });
    window.location.reload(false);
    // return <Navigate to="/login" />;
  }
  if (error?.response) {

    Toast({
      message: "Something went wrong",
      type: "error",
    });
    // throw new Error(
    //   error?.response?.data?.msg ||
    //     error?.response?.data?.message ||
    //     error?.response?.data?.body?.error ||
    //     "Something went wrong"
    // );
  } else if (error?.request) {
    throw new Error("No response received from the server");
  } else {
    throw new Error(error?.message || "Something went wrong");
  }
};

export const getToken = () => localStorage.getItem("__user_token__");

const apiRequest = async (method, path, payload = null, headers = {}, secure) => {
  let url = `${API_BASE_URL}${path}`;
  const methodIsGet = method === "GET" || method === "DELETE";

  if (methodIsGet) {
    url = payload ? `${url}?${qs.stringify(payload)}` : url;
  }
  const config = {
    method,
    url,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  };

  if (payload && !methodIsGet) {
    config.data = payload;
  }

  if (secure) {
    const token = getToken();
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Token ${token}`;
    } else {
      localStorage.clear();
      Toast({
        message: "Need to login first. Please login and try again.",
        type: "error",
      });
      return <Navigate to="/login" />;
    }
  }

  try {
    const response = await axios(config);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const get = (path, queryParams = {}, headers = {}, secure = true) => {
  return apiRequest("GET", path, queryParams, headers, secure);
};

export const post = (path, body, headers = {}, secure = true) => {
  return apiRequest("POST", path, body, headers, secure);
};

export const put = (path, body, headers = {}, secure = true) => {
  return apiRequest("PUT", path, body, headers, secure);
};

export const patch = (path, body, headers = {}, secure = true) => {
  return apiRequest("PATCH", path, body, headers, secure);
};

export const del = (path, queryParams = {}, headers = {}, secure = true) => {
  return apiRequest("DELETE", path, queryParams, headers, secure);
};
