import React, { useState } from "react";
import { Avatar, Card, Flex } from "antd";
import { UserOutlined } from "@ant-design/icons";
import {
  bagIcon,
  bilidingIcon,
  bookIcon,
  mapIcon,
  walletIcon,
} from "../../assets";
import "../../styles/candidate-card.css";
import { post } from "../../lib/Api";
import Toast from "../Toast/Toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import dayjs from "dayjs";

const CandidateAppliedJobCard = ({
  job,
  handleVisibleDetails,
  isSelected,
  onSelectChange,
  fetchData,
}) => {
  const [loading, setLoading] = useState(false);
  const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);

  const handleApplyJob = async () => {
    try {
      setLoading(true);
      const data = {
        job_id: job?.id,
      };
      const response = await post("candidate/apply-job", data, "", true);
      if (response?.status === true) {
        Toast({ message: "Job application successful", type: "success" });
        fetchData();
        setLoading(false);
      } else {
        Toast({ message: response?.message, type: "error" });
      }
    } catch (error) {
      console.error("Failed to Apply job", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        title={
          <>
            <Flex
              justify="space-between"
              align="center"
              className="candidate-user-info"
            >
              <div>
                <Avatar icon={<UserOutlined />} />
                <h2>{job?.job_title}</h2>
                {/* <RightOutlined /> */}
              </div>
            </Flex>
            <div className="candidate-user-bio">
              <div className="user-bio-details">
                <div className="user-bio-list">
                  <img src={bagIcon} alt="" />
                  <span>
                    {job?.min_experience} - {job?.max_experience} year
                  </span>
                </div>
                <div className="user-bio-list">
                  <img src={walletIcon} alt="" />
                  <span>
                    ₹ {job?.min_ctc} - {job?.max_ctc}
                  </span>
                </div>
                <div className="user-bio-list">
                  <img src={mapIcon} alt="" />
                  <span>{job?.location}</span>
                </div>
              </div>
            </div>
            <div className="user-bio-info">
              <div className="user-bio-labels">
              <div className="bio-item">
                  <img src={bookIcon} alt="" />
                  <span>No. of Position : </span>
                  <span className="user-bio-values">
                    {job?.number_of_open_position}
                  </span>
                </div>
                <div className="bio-item">
                  <img src={bilidingIcon} alt="" />
                  <span>Industry : </span>
                  <span className="user-bio-values">{job?.industry}</span>
                </div>
                
                <div className="bio-item">
                  <img src={bookIcon} alt="" />
                  <span>Skills : </span>
                  <span className="user-bio-values">
                    {job?.skills_name?.join(", ")}
                  </span>
                </div>

                <div className="bio-item">
                  <img src={bilidingIcon} alt="" />
                  <span>Job Posting Date : </span>
                  <span className="user-bio-values">
                    {job?.created_at
                      ? dayjs(job?.created_at).format("DD-MM-YYYY")
                      : "N/A"}
                  </span>
                </div>

              </div>
            </div>
          </>
        }
        className="candidate-card"
      >
        {/* <Flex
          gap="small"
          wrap
          justify="flex-end"
          className="candidate-button-group"
        >
          {job?.is_applied ? (
            <Button
              type="default"
              size="large"
              disabled
              // icon={<img src={Downolad1Icon} alt="icon" />}
            >
              Applied
            </Button>
          ) : (
            <Button
              type="default"
              size="large"
              // icon={<img src={Downolad1Icon} alt="icon" />}
              onClick={() => {
                setIsApplyModalVisible(true);
              }}
            >
              Apply
            </Button>
          )}
        </Flex> */}
      </Card>

      <ConfirmationModal
        isOpen={isApplyModalVisible}
        onConfirm={() => {
          handleApplyJob();
        }}
        onCancel={() => setIsApplyModalVisible(false)}
        loading={loading}
        title="Confirm Apply"
        message="Are you sure you want to apply for this job?"
      />
    </>
  );
};

export default CandidateAppliedJobCard;
