import { Outlet, useNavigate } from "react-router-dom";
import "../../styles/MainHeader.css";
import { Button, Dropdown, Form, Input, Modal } from "antd";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useState } from "react";
import Toast from "../Toast/Toast";
import { post } from "../../lib/Api";
import { chakkraLogo, menuIcon, settingIcon } from "../../assets";

function MainHeader({ handleMenu }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
  useState(false);
  const [form] = Form.useForm();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleChangePasswordFinish = async (values) => {
    try {
      let res;

      const data = {
        oldpassword: values.old_password,
        newpassword: values.confirm_password,
      };
      res = await post(`user/change-password`, data, {}, true);
      if (res?.status === true) {
        Toast({ message: "Password successfully changed", type: "success" });
      } else {
        Toast({ message: res?.message, type: "error" });
      }

      if (res?.status === true) {
        form.resetFields();
        setIsChangePasswordModalOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast({
        message: `Failed to update password`,
        type: "error",
      });
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <div
        onClick={() => {
          setIsChangePasswordModalOpen(true);
        }}
          rel="noopener noreferrer"
        >
          Change Password
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setIsLogoutModalOpen(true);
          }}
          rel="noopener noreferrer"
        >
          Log Out
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="main-header">
        <div className="header-left">
          <img
            // src="menu-icon.svg"
            src={menuIcon}
            alt="Menu Icon"
            className="menu-icon"
            onClick={handleMenu}
          />
          <img src={chakkraLogo} alt="Chakkra Logo" className="logo" />
        </div>
        <Dropdown menu={{ items }} placement="bottomRight">
          {/* <div className="header-right"> */}
            <img src={settingIcon} alt="Setting"/>
          {/* </div> */}
        </Dropdown>
      </div>

      <Outlet />

      <ConfirmationModal
        isOpen={isLogoutModalOpen}
        onConfirm={handleLogout}
        onCancel={() => setIsLogoutModalOpen(false)}
        loading={loading}
        title="Confirm Log out"
        message={`Are you sure you want to log out of this device?`}
      />

      <Modal
        title="Change Password"
        centered
        open={isChangePasswordModalOpen}
        onCancel={() => {
          setIsChangePasswordModalOpen(false);
        }}
        footer={[
          <Button
            key="cancel"
            className="create-modal-cancel-button"
            onClick={() => {
              setIsChangePasswordModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            className="create-modal-confirm-button"
            onClick={() => form.submit()}
          >
            Confirm
          </Button>,
        ]}
        width={500}
      >
        <Form
          form={form}
          name="change_password"
          initialValues={{ remember: true }}
          onFinish={handleChangePasswordFinish}
        >
          <Form.Item
            label="Old Password"
            name="old_password"
            rules={[
              { required: true, message: "Please input your old password!" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: true, message: "Please input your new password!" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            rules={[
              { required: true, message: "Please confirm your new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default MainHeader;
